import React from "react";
import styled from "styled-components";

export default function Logo(props) {
  return (
    <Wrap>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 113.84 100.9"
      >
        <path class="cls-1" d="M0,32.77" transform="translate(3.62 -0.3)" />
        <text class="cls-2" transform="matrix(0.02, 1, -1, 0.02, 3.97, 30.75)">
          T
        </text>
        <text class="cls-2" transform="translate(4.04 36.42) rotate(85.76)">
          A
        </text>
        <text class="cls-2" transform="translate(4.57 43.78) rotate(80.84)">
          M
        </text>
        <text class="cls-2" transform="translate(6.19 53.28) rotate(75.32)">
          P
        </text>
        <text class="cls-2" transform="translate(7.99 60.01) rotate(70.11)">
          E
        </text>
        <text class="cls-2" transform="translate(10.26 66.23) rotate(63.8)">
          R
        </text>
        <text class="cls-2" transform="translate(13.69 73.02) rotate(56.41)">
          E
        </text>
        <text
          class="cls-2"
          transform="matrix(0.66, 0.75, -0.75, 0.66, 17.47, 78.59)"
        >
          E
        </text>
        <text class="cls-2" transform="translate(22.05 83.72) rotate(39.29)">
          N
        </text>
        <text
          class="cls-2"
          transform="translate(28.24 88.54) rotate(31.83)"
        ></text>
        <text
          class="cls-2"
          transform="translate(31.27 90.39) rotate(27.42)"
        ></text>
        <text class="cls-2" transform="translate(34.59 92.16) rotate(20.89)">
          K
        </text>
        <text class="cls-2" transform="translate(41.57 94.74) rotate(12.89)">
          R
        </text>
        <text class="cls-2" transform="translate(48.81 96.27) rotate(7.7)">
          I
        </text>
        <text class="cls-2" transform="matrix(1, 0.07, -0.07, 1, 52.5, 96.78)">
          S
        </text>
        <text class="cls-2" transform="translate(59.94 96.93) rotate(-6.82)">
          T
        </text>
        <text class="cls-2" transform="translate(66.32 96.1) rotate(-11.23)">
          I
        </text>
        <text class="cls-2" transform="translate(70.1 95.37) rotate(-16.16)">
          L
        </text>
        <text class="cls-2" transform="translate(76.27 93.53) rotate(-23.18)">
          L
        </text>
        <text class="cls-2" transform="translate(82.1 90.91) rotate(-29.38)">
          I
        </text>
        <text
          class="cls-2"
          transform="matrix(0.8, -0.6, 0.6, 0.8, 85.66, 88.98)"
        >
          N
        </text>
        <text
          class="cls-2"
          transform="matrix(0.69, -0.72, 0.72, 0.69, 91.99, 84.05)"
        >
          E
        </text>
        <text
          class="cls-2"
          transform="matrix(0.57, -0.82, 0.82, 0.57, 96.69, 79.04)"
        >
          N
        </text>
        <text
          class="cls-2"
          transform="translate(100.93 72.63) rotate(-61.63)"
        ></text>
        <text
          class="cls-2"
          transform="matrix(0.42, -0.91, 0.91, 0.42, 102.56, 69.55)"
        ></text>
        <text class="cls-2" transform="translate(104.13 66.26) rotate(-70.14)">
          K
        </text>
        <text class="cls-2" transform="translate(106.54 59.44) rotate(-76.09)">
          O
        </text>
        <text
          class="cls-2"
          transform="matrix(0.15, -0.99, 0.99, 0.15, 108.28, 52.25)"
        >
          U
        </text>
        <text class="cls-2" transform="translate(109.32 45.23) rotate(-85.37)">
          L
        </text>
        <text class="cls-2" transform="translate(109.81 39.06) rotate(-89.31)">
          U
        </text>
        <path class="cls-1" d="M54.86,19.22" transform="translate(3.62 -0.3)" />
        <path class="cls-1" d="M15,40.11" transform="translate(3.62 -0.3)" />
        {/* <g id="SHAPE"> */}
        <path class="cls-1" d="M0,32.8" transform="translate(3.62 -0.3)" />
        <path class="cls-1" d="M54.93,19.2" transform="translate(3.62 -0.3)" />
        {/* </g><g id="Layer_1-2" data-name="Layer 1"> */}
        <path
          class="cls-1"
          d="M64.53,62.8c2.8,2.8,5.1,10.1,4.4,17.5a2.22,2.22,0,0,1-2.5,1.7,2.47,2.47,0,0,1-2.6-1.8c-.3-1.7.1-8.3-2.2-12.3-1.7-3-3.8-4.9-8.7-5.2a20.08,20.08,0,0,1,2,5.6,21.75,21.75,0,0,1-.8,13.8,2.45,2.45,0,0,1-3,1.5,2.51,2.51,0,0,1-1.7-3.2,21,21,0,0,0,1.2-5.4c.2-3.8-.7-9.8-4.7-12.2-5.2-2.4-13.3-1.4-17.2-8.4-1.7-2.5-2.1-5-2.5-7.9-.2-3.6-.2-8.4-.2-12l-9.8,5.1a2.44,2.44,0,0,1-2.5-4.2l17.2-9.1L31,42.4c0,1.3-.9,10.8,7.5,13.4-2.4-3.6-2.4-7.8-2.4-13.1V23.5l15-7.9a2.85,2.85,0,0,1,3.5.9,2.53,2.53,0,0,1-1,3.4l-12.4,6.5s-.1,18.5,0,19.6c.2,2.7.9,10.3,7.2,11.1,4.5.5,8.3.3,13.3.4a24.34,24.34,0,0,1,8.9,1.3c5.9,1.7,10.3,8.1,11.1,13.7.3,1.6-.3,2.8-1.8,3.1a2.46,2.46,0,0,1-3.1-2.1C75.83,69.2,72.43,62.8,64.53,62.8Z"
          transform="translate(3.62 -0.3)"
        />
        <path
          class="cls-1"
          d="M77.63,23.6c10.3,0,13.6,7.4,13.9,11.9.8,4.3-.3,12.9-.4,13.3-.8-3.9-1.3-7.7-3-11.3-1.7-4.9-7.5-8.6-13.5-9-4.3,5.4-13.9,11.6-15,12,3.1-3.6,7-7.3,9.7-11.5-4.9.9-9.7,3.2-14.3,4.6,4.8-3.8,12.2-8.2,16.5-9.1A14.71,14.71,0,0,0,68.43,8c-2.5-2.6-5.9-4.7-9-6.8,3.3.5,11,3,14.6,6.1,4.1,3.2,6.4,9.5,3.6,16.3"
          transform="translate(3.62 -0.3)"
        />
        <path
          class="cls-1"
          d="M41.13,9.5l10.1-5.2a2.55,2.55,0,0,1,3.2,1.3,2.33,2.33,0,0,1-.9,3.1l-17.4,9.2V2.4A2.45,2.45,0,0,1,38.73.3a2.31,2.31,0,0,1,2.4,2.4Z"
          transform="translate(3.62 -0.3)"
        />
        <path
          class="cls-1"
          d="M30.83,20.7s-9.4,5-13.7,7.2a2.68,2.68,0,0,1-3.6-1.1,2.91,2.91,0,0,1,1.2-3.6c3.3-1.8,11.2-5.9,11.2-5.9V7.5c0-1.2.9-1.9,2.3-2s2.6.6,2.6,1.9Z"
          transform="translate(3.62 -0.3)"
        />
        <path
          class="cls-1"
          d="M82.23,23.2a2.69,2.69,0,0,1-2.7-2.7A2.56,2.56,0,0,1,82,17.9a2.86,2.86,0,0,1,2.8,2.8A2.5,2.5,0,0,1,82.23,23.2Z"
          transform="translate(3.62 -0.3)"
        />
        <path class="cls-1" d="M54.93,19.2" transform="translate(3.62 -0.3)" />
      </svg>
    </Wrap>
  );
}

{
  /* <style>.cls-1,.cls-2{fill:#114467;}.cls-2{font-size:11.93px;font-family:HelveticaNeue-CondensedBold, Helvetica Neue;font-weight:700;}</style> */
}

const Wrap = styled.div`
  font-size: 11.93px;
  font-family: HelveticaNeue-CondensedBold, Helvetica Neue;
  font-weight: 700;
`;
