import React from "react";
import { Link } from "@reach/router";
import styled from "styled-components";
//
import Logo from "components/takriko.js";
//
export default ({ mobileActive, onClick, ...rest }) => (
  <Wrap>
    <Link
      to="/"
      title="Takriko Home"
      onClick={mobileActive && onClick}
      {...rest}
    >
      <Logo />
    </Link>
  </Wrap>
);
//
const Wrap = styled.div`
  cursor: pointer;
  display: block;
  outline: none;
  // padding: 0.15rem;
  position: fixed;
  left: 0.5rem;
  top: 0.5rem;
  height: 2.5rem;
  width: 2.5rem;

  @media (min-width: 769px) {
    padding: 0.25rem;
    position: initial;
    left: 1rem;
    top: 0.75rem;
    height: 75px;
    width: 75px;
  }
`;
