import React from "react";
import { Toggle } from "react-powerplug";
import styled from "styled-components";
//
import Logo from "./logo";
import Navigation from "./menu";
import Bars from "components/svg/fontawesome/Bars";
import Close from "components/svg/fontawesome/Close";
//
export default ({ ...rest }) => (
  <Toggle initial={false}>
    {({ on, toggle }) => (
      <Wrap mobileActive={on}>
        <Header>
          <Logo mobileActive={on} onClick={toggle} />
          <IconWrap active={on} onClick={toggle}>
            {!on && <Bars />}
            {on && <Close />}
          </IconWrap>
        </Header>
        <Navigation mobileActive={on} onClick={toggle} {...rest} />
      </Wrap>
    )}
  </Toggle>
);
//

const Wrap = styled.div`
  bottom: ${({ mobileActive }) => (mobileActive ? 0 : "auto")};
  min-height: 3rem;
  min-height: calc(3rem + 2px);
  z-index: 110;

  left: 0px;
  -webkit-box-pack: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  width: 100%;
  overflow: scroll;

  svg {
    fill: #213e65;
    width: 100%;

    :hover {
      fill: #f74c4c;
    }
  }

  @media (min-width: 769px) {
    bottom: 0px;
    height: 100vh;
    max-width: 240px;
    position: fixed;
    text-align: left;
    overflow: scroll;
    padding: 1rem;
  }
`;
const Header = styled.header``;
const IconWrap = styled.div`
  background-color: transparent;
  border: 0;
  box-shadow: 0;
  color: inherit;
  cursor: pointer;
  display: block;
  line-height: 0;
  outline: none;
  padding: 0.25rem;
  position: fixed;
  right: 0.5rem;
  top: 0.25rem;
  height: 2.5rem;
  width: 2.5rem;
  z-index: 2010;
  align-self: center;

  @media (min-width: 769px) {
    display: none;
  }
`;
